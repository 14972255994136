import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  CreateButton
} from "react-admin";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

//Empty page
const Empty = ({ basePath, resource }) => (
  <Box textAlign="center" m={4}>
    <Typography variant="h4" paragraph>
      Inga webshoppar finns sparade
    </Typography>

    <CreateButton basePath={basePath} label="Skapa webshop" />
  </Box>
);
export const WebshopList = (props) => (
  <List {...props} title="Webshopar" empty={<Empty></Empty>}>
    <Datagrid rowClick="show">
      <TextField source="name" label="Namn" />
      <DateField source="createdDate" label="Skapad" />
    </Datagrid>
  </List>
);
