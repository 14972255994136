// in src/App.js
import * as React from "react";
import {
  Admin,
  Resource,
  EditGuesser,
  ShowGuesser,
  fetchUtils
} from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import dProvider from "./DataProvider/Provider"

//Icons
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import StorefrontIcon from "@material-ui/icons/Storefront";
import MyLayout from "./Custom/MyLayout";

//Theme
import ThemeOne from "./Themes/ThemeOne";

//Lists
import { ArticleList } from "./Lists/ArticleList";
import { WebshopList } from "./Lists/WebshopList";
//import { ArticleShow } from "./Show/ArticleShow";
import ArticleShow from "./Show/Custom/ArticleShow";
import { WebshopShow } from "./Show/WebshopShow";
import { ArticleEdit } from "./Edit/ArticleEdit";
import { VariantEdit } from "./Edit/VariantEdit";
import { CategoryEdit } from "./Edit/CategoryEdit";
import { CategoryCreate } from "./Create/CategoryCreate";
import { VariantCreate } from "./Create/VariantCreate";
import { ArticleCreate } from "./Create/ArticleCreate";
import { WebshopCreate } from "./Create/WebshopCreate";
import { WebshopEdit } from "./Edit/WebshopEdit";
import { WebshoparticleEdit } from "./Edit/WebshopArticleEdit";
import { WebshoparticleCreate } from "./Create/WebshopArticleCreate";
import { WebshopDesignEdit } from "./Edit/WebshopDesignEdit";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { OrderList } from "./Lists/OrderList";
import OrderShow from "./Show/Custom/OrderShow";
import swedishMessages from "ra-language-swedish";
import UserProfileShow from "./UserProfile/Show/UserProfileShow";
import { Urls } from "./Urls";

import { Route } from "react-router-dom";

//AUTH
import authProvider from "./Auth/authProvider";
import CustomRoutes from "./Auth/CustomRoutes";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
//Dataprovider
const dataProvider = dProvider;
const i18nProvider = polyglotI18nProvider(() => swedishMessages, "sv");

const App = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    title="Retail Hut"
    theme={ThemeOne}
    i18nProvider={i18nProvider}
    customRoutes={CustomRoutes}
    layout={MyLayout}
  >
    <Resource
      name="article"
      list={ArticleList}
      show={ArticleShow}
      edit={ArticleEdit}
      create={ArticleCreate}
      options={{ label: "Artiklar" }}
      icon={ShoppingBasketIcon}
    />
    <Resource
      name="webshop"
      list={WebshopList}
      create={WebshopCreate}
      show={WebshopShow}
      edit={WebshopEdit}
      options={{ label: "Webbshopar" }}
      icon={StorefrontIcon}
    ></Resource>
    <Resource
      name="order"
      list={OrderList}
      options={{ label: "Orderhistorik" }}
      show={OrderShow}
    ></Resource>
    <Resource
      name="variant"
      edit={VariantEdit}
      create={VariantCreate}
    ></Resource>
    <Resource
      name="webshoparticle"
      edit={WebshoparticleEdit}
      create={WebshoparticleCreate}
    ></Resource>
    <Resource
      name="category"
      edit={CategoryEdit}
      create={CategoryCreate}
    ></Resource>

    <Resource name="webshopdesign" edit={WebshopDesignEdit}></Resource>
    <Resource name="profile" show={UserProfileShow} />
  </Admin>
);
export default App;
