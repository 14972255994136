import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  Toolbar,
  SaveButton,
  DeleteButton
} from "react-admin";

const WithProps = ({ children, ...props }) => children(props);

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton></SaveButton>
    <WithProps>
      {({ record, ...props }) => (
        <DeleteButton
          record={record}
          {...props}
          redirect={`/webshop/${record.webshopId}/show/categories`}
        ></DeleteButton>
      )}
    </WithProps>
  </Toolbar>
);

export const CategoryEdit = (props) => (
  <Edit {...props}>
    <WithProps>
      {({ record, ...props }) => (
        <SimpleForm
          record={record}
          {...props}
          redirect={`/webshop/${record.webshopId}/show/categories`}
          toolbar={<EditToolbar></EditToolbar>}
        >
          <ReferenceInput source="webshopId" reference="webshop">
            <SelectInput optionText="name" disabled />
          </ReferenceInput>
          <TextInput source="name" validate={required()} />
        </SimpleForm>
      )}
    </WithProps>
  </Edit>
);
