import * as React from "react";
import {
  Button,
  Card,
  Grid,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  IconButton
} from "@material-ui/core";
import { EditButton, CloneButton, Show, useRefresh } from "react-admin";
import EditIcon from "@material-ui/icons/Edit";
import FileCopy from "@material-ui/icons/FileCopy";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { makeStyles } from "@material-ui/core/styles";
import { useShowController, useQuery, Loading, Error } from "react-admin";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  root: {},
  spacing: {
    margin: theme.spacing(3)
  },
  listItem: {
    marginBottom: theme.spacing(2)
  },
  price: {
    color: "green"
  },
  variantList: {
    position: "relative",
    overflow: "auto",
    maxHeight: 350
  },
  card: {
    maxHeight: 410,
    overflow: "auto"
  },
  gridHeight: {
    maxHeight: 455,
    minHeight: 300
  }
}));

//Custom button for editing
const CustomEditButton = (variant) => {
  return (
    <IconButton
      component={Link}
      to={{ pathname: "/variant/" + variant.id }}
      color="primary"
      variant="outlined"
    >
      <EditIcon></EditIcon>
    </IconButton>
  );
};

const CreateVariantButton = (record) => (
  <Button
    component={Link}
    to={{
      pathname: "/variant/create",
      state: {
        record: { articleId: record.id, variantImage: record.articleImage }
      }
    }}
    outlined
    color="primary"
    variant="contained"
  >
    Skapa Ny Variant
  </Button>
);

const CloneVariant = (item) => {
  return (
    <IconButton
      component={Link}
      variant="outlined"
      color="primary"
      to={{
        pathname: "/variant/create",
        state: {
          record: {
            articleId: item.articleId,
            name: item.name,
            variantDescription: item.variantDescription,
            storageAmount: item.storageAmount,
            variantImage: item.variantImage
          }
        }
      }}
    >
      <FileCopy></FileCopy>
    </IconButton>
  );
};

const ListOfVariants = (props) => {
  const { data, loading, error } = useQuery({
    type: "getManyReference",
    resource: "variant",
    payload: {
      pagination: { page: 0, perPage: 100 },
      sort: { field: "name", order: "ASC" },
      filter: { articleId: props.id }
    }
  });

  if (loading) { 
    return <CircularProgress></CircularProgress> 
  };
  if (error) {
    window.location.reload()
    return <p>...</p>
  };
  if (!data) return null;

  return (
    <div>
      <List>
        {data.map((item) => (
          <ListItem key={item.id}>
            <ListItemAvatar>
              {item.variantImage && (
                <Avatar alt="asd" src={item.variantImage}></Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              secondary={"I lager: " + item.storageAmount}
            ></ListItemText>

            <ListItemSecondaryAction>
              <Grid spacing={2}>
                <CustomEditButton {...item}></CustomEditButton>
                <CloneVariant {...item}></CloneVariant>
              </Grid>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

const WebshopsWithTheArticle = (props) => {
  const { data, loading, error } = useQuery({
    type: "getManyReference",
    resource: "webshop",
    payload: {
      pagination: { page: 0, perPage: 100 },
      sort: { field: "name", order: "ASC" },
      filter: { articleId: props.id }
    }
  });

  if (loading) return <CircularProgress></CircularProgress>;
  if (error) {
    window.location.reload()
    return <CircularProgress></CircularProgress>;
  }
  if (!data) return null;
  if (data.length == 0) return null;

  return (
    <div>
      <Card>
        <CardHeader title="Artikeln finns i: "></CardHeader>
        <List>
          {data.map((item) => (
            <ListItem
              key={item.id}
              component={Link}
              button
              to={{ pathname: `/webshop/${item.id}/show/webshoparticle` }}
            >
              <ListItemIcon>
                <StorefrontIcon></StorefrontIcon>
              </ListItemIcon>
              <ListItemText primary={item.name}></ListItemText>
            </ListItem>
          ))}
        </List>
      </Card>
    </div>
  );
};

const MyShow = (props) => {
  const {
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    loaded, // boolean that is false until the record is available
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version // integer used by the refresh feature
  } = useShowController(props);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {loaded && (
        <div>
          <Grid container spacing={3}>
            <Grid item mt={2}>
              <Avatar
                alt="Ingen bild"
                src={record.articleImage}
                className={classes.spacing}
              />
            </Grid>
            <Grid item>
              <h1>{record.name}</h1>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Card>
                <CardHeader title="Info"></CardHeader>
                <CardContent>
                  <div className={classes.listItem}>
                    <Typography variant="body1">Namn: </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {record.name}
                    </Typography>
                  </div>
                  <div className={classes.listItem}>
                    <Typography variant="body1">Märke/Leverantör: </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {record.supplierName}
                    </Typography>
                  </div>

                  <div className={classes.listItem}>
                    <Typography variant="body1">Bildkälla (URL): </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      <a href={record.articleImage} target="_blank">
                        {record.articleImage}
                      </a>
                    </Typography>
                  </div>
                  <div className={classes.listItem}>
                    <Typography variant="body1">
                      Inköpspris (inklusive moms):{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {record.buyPrice} :-
                    </Typography>
                  </div>
                  <div className={classes.listItem}>
                    <Typography variant="body1">
                      Rekommenderat pris (inklusive Moms):
                    </Typography>
                    <h2 className={classes.price}>
                      {record.standardSellPrice} :-
                    </h2>
                  </div>
                  <hr></hr>
                  <div className={classes.listItem}>
                    <Typography variant="body1">Beskrivning: </Typography>

                    <div>{parse(record.description)}</div>
                  </div>
                </CardContent>
                <CardActions>
                  {" "}
                  <EditButton
                    basePath={basePath}
                    record={record}
                    variant="contained"
                  ></EditButton>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card>
                <CardHeader title="Varianter"></CardHeader>

                <CardContent className={classes.card}>
                  <ListOfVariants {...props}></ListOfVariants>
                </CardContent>
                <CardActions>
                  {" "}
                  <CreateVariantButton {...record}></CreateVariantButton>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} lg={12}>
              <WebshopsWithTheArticle {...props}></WebshopsWithTheArticle>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

const ArticleShow = (props) => <MyShow {...props}></MyShow>;

export default ArticleShow;
