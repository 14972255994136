import * as React from "react";
import { Edit, SimpleForm, TextInput, Toolbar, SaveButton } from "react-admin";

const WithProps = ({ children, ...props }) => children(props);

//Custom toolbar
const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton></SaveButton>
  </Toolbar>
);

export const WebshopDesignEdit = (props) => (
  <Edit {...props}>
    <WithProps>
      {({ record, ...props }) => (
        <SimpleForm
          record={record}
          {...props}
          redirect={`/webshop/${record.webshopId}/show`}
          toolbar={<CustomToolbar red={record} />}
        >
          <TextInput source="headerImage" label="Header (URL)" />
          <TextInput source="webshopLogoImage" label="Webshoplogga (URL)" />
        </SimpleForm>
      )}
    </WithProps>
  </Edit>
);
