import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  ImageInput,
  ImageField,
  FormDataConsumer,
} from "react-admin";

import RichTextInput from "ra-input-rich-text";

const WithProps = ({ children, ...props }) => children(props);

export const ArticleCreate = (props) => (
  <Create {...props}>
       <WithProps>
      {({ record, ...props }) => (
      <SimpleForm redirect="show" record={record}  {...props}  redirect={`/article`}>
        <TextInput
          source="name"
          label="Namn"
          validate={required()}
          variant="outlined"
        />

        <TextInput
          source="articleNumber"
          label="Artikelnummer"
          variant="outlined"
          validate={required()}
        />
        <TextInput
          source="supplierName"
          label="Märke/Leverantör"
          variant="outlined"
          validate={required()}
        />

        <ImageInput source="articleImageContainer" label="Artikelbild" accept="image/*" placeholder={<p>Dra och släpp bilden här</p>} >
          <ImageField source="src" title="title" />
        </ImageInput>

        <FormDataConsumer>
              {({formData, dispatch, ...rest}) => {
                  if (!formData.articleImageContainer) {
                      return (
                          <div>
                              <ImageField source="articleImage" {...rest}/>
                          </div>
                    );
                  } 
        
              }}
          </FormDataConsumer>

        <NumberInput
          source="buyPrice"
          label="Inköpspris (inkl Moms)"
          variant="outlined"
          helperText="Detta pris syns inte för kund"
        />
        <NumberInput
          source="standardSellPrice"
          label="Rekommenderat pris (inkl Moms)"
          variant="outlined"
          validate={required()}
        />
        <RichTextInput
          source="description"
          label="Beskrivning"
          variant="outlined"
          validate={required()}
        />
      </SimpleForm>
       )}
       </WithProps>
  </Create>
);

