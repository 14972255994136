import { createMuiTheme } from "@material-ui/core/styles";
import "../Custom/App.css";

const palette = {
  primary: { main: "rgb(0, 56, 98)" },
  secondary: { main: "rgb(0, 56, 98)" }
};

const ThemeOne = createMuiTheme({ palette });

export default ThemeOne;
