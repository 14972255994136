import * as React from "react";
import {
  Button,
  Card,
  Grid,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  CardHeader,
  List,
  Link,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  IconButton
} from "@material-ui/core";
import { useShowController, useQuery, Loading, Error } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  spacing: {
    margin: theme.spacing(3)
  },
  listItem: {
    marginBottom: theme.spacing(2)
  },
  price: {
    color: "green"
  },
  variantList: {
    position: "relative",
    overflow: "auto",
    maxHeight: 350
  },
  card: {
    maxHeight: 410,
    overflow: "auto"
  },
  gridHeight: {
    maxHeight: 455,
    minHeight: 300
  }
}));
const CustomShow = (props) => {
  const {
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    loaded, // boolean that is false until the record is available
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version // integer used by the refresh feature
  } = useShowController(props);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {loaded && (
        <div>
          <Grid container spacing={3}>
            <Grid item mt={2}></Grid>
            <Grid item>
              <h1>Kontoinställningar</h1>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Grid item>
                <Card>
                  <CardHeader title="Info"></CardHeader>
                  <CardContent>
                    <Typography variant="body1">Email: </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {record.email}
                    </Typography>
                    <br></br>
                    <Typography variant="body1"> Telefon:</Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {record.phone !== null
                        ? record.phone
                        : "Du har inte fyllt i detta"}
                    </Typography>
                    <br></br>
                    <Typography variant="body1">
                      {" "}
                      Organisation/Företag:
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {record.organization !== null
                        ? record.organization
                        : "Du har inte fyllt i detta"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid item>
                <Card>
                  <CardHeader title="Betalningsinställningar"></CardHeader>
                  <CardContent>
                    <Typography variant="body1">Kortbetalning: </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {record.stripeAccountId !== null
                        ? "Du har aktiverat Kortbetalningar med Stripe"
                        : "Ej aktiverat"}
                    </Typography>
                    <br></br>
                    {record.stripeAccountId === null && (
                      <div>
                        <Link href="https://connect.stripe.com/oauth/authorize?client_id=ca_HEyIuTvIgYBETgGZOheJku2PaQdssjod&state=231d243456h56vk8994nn89&scope=read_write&response_type=code">
                          <Button color="primary" variant="contained">
                            Aktivera Stripe-konto
                          </Button>
                        </Link>
                      </div>
                    )}
                    <br></br>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

const UserProfileShow = (props) => <CustomShow {...props}></CustomShow>;

export default UserProfileShow;
