import * as React from "react";
import {
  Button,
  Card,
  Grid,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  CardHeader,
  List,
  Link,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  AppBar,
  Toolbar,
  Divider,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import OrderStepper from "./OrderStepper";
import {
  useMutation,
  Button as Btn,
  useRefresh,
  useRedirect
} from "react-admin";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const UpdateButton = (props) => {
  const refresh = useRefresh();
  const redirect = useRedirect();

  const load = {
    type: "update",
    resource: "order",
    payload: {
      id: props.record.id,
      data: {
        order: props.record,
        orderHistoryJson: props.record.orderHistoryJson,
        orderArticlesJson: props.record.orderArticlesJson
      }
    }
  };

  const [approve, { loading }] = useMutation(load, {
    onSuccess: () => {
      refresh();
    }
  });

  const closeAndUpdate = () => {
    approve();

    props.closeDialog();
  };

  return <Btn label="Uppdatera" onClick={approve} disabled={loading} />;
};

//Transition animation
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderStatusUpdater = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [steps, setSteps] = React.useState(props.record.orderHistory);
  const [selected, setSelected] = React.useState("");
  const [record, setRecord] = React.useState(props.record);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleUpdate = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setSelected(event.target.value);

    const today = new Date();
    const obj = {
      comment: event.target.value,
      createdDate: today.toJSON(),
      isDone: false
    };

    const arr = steps;
    let res = arr.concat(obj);
    for (var i = 0; i < res.length - 1; i++) {
      res[i].isDone = true;
    }

    record.orderHistoryJson = JSON.stringify(res);
    record.orderHistory = res;
    record.orderStatus = obj.comment;
    setRecord(record);
  };

  const handleClose = () => {
    setSteps(props.record.orderHistory);
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Uppdatera Orderstatus
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle id="form-dialog-title">Uppdatera Orderstatus</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item lg={6} xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Välj status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selected}
                  onChange={handleChange}
                >
                  <em>None</em>
                  <MenuItem value={"Betalning mottagen"}>
                    Betalning mottagen
                  </MenuItem>
                  <MenuItem value={"Under behandling"}>
                    Under behandling
                  </MenuItem>
                  <MenuItem value={"Färdig för leverans"}>
                    Färdig för leverans
                  </MenuItem>
                  <MenuItem value={"Delvis levererad"}>
                    Delvis levererad
                  </MenuItem>
                  <MenuItem value={"Levererad"}>
                    Levererad
                  </MenuItem>
                  <MenuItem value={"Klar"}>
                    Klar
                  </MenuItem>
                  <MenuItem value={"Invänta Leverans"}>
                    Invänta Leverans
                  </MenuItem>
                  <MenuItem value={"Avslutad"}>Avslutad</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <UpdateButton
            record={record}
            closeDialog={handleUpdate}
            isDisabled={selected === ""}
          ></UpdateButton>
          <Button autoFocus onClick={handleClose} variant="outlined">
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderStatusUpdater;
