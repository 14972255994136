import { Urls } from "../../Urls";

export const Api = {
  getPrice(articleId) {
    if (articleId === undefined) return null;

    //Get bearer
    const token = localStorage.getItem("token");
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    return fetch(`${Urls.api}/article/${articleId}`, {
      method: "GET",
      headers: headers
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        return err;
      });
  },
  simulateUser(userEmail) {
    if (userEmail === null) return null;
    //Get bearer

    const token = localStorage.getItem("token");
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    return fetch(`${Urls.api}/admin/users`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ userName: userEmail, password: userEmail })
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        localStorage.setItem("token", res.token);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  register(credentials) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    return fetch(`${Urls.api}/login/register`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(credentials)
    })
      .then((res) => {
        return res;
      })

      .catch((err) => {
        return err;
      });
  }
};
