import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,

} from "react-admin";
import Box from "@material-ui/core/Box";
import { useLocale } from "react-admin";
import Typography from "@material-ui/core/Typography";

//Empty page
const Empty = ({ basePath, resource }) => (
  <Box textAlign="center" m={4}>
    <Typography variant="h4" paragraph>
      Här var det tomt. Inga kunder har beställt något än
    </Typography>
  </Box>
);


const parser = (date) => {
  return "sfasdasd"
}
export const OrderList = (props) => {

  return (
    <List {...props} title="Ordrar" empty={<Empty></Empty>} sort={{ field: 'lastEdited', order: 'DESC' }}>
      <Datagrid rowClick="show">
        <TextField source="email" label="Kund" />
        <TextField source="webshopName" label="Webshop"></TextField>
        <DateField
          source="createdDate"
          label="Beställd"
          showTime={true}
          locales={"sv-SE"}
          options={parser}     
        />
        <DateField
          source="lastEdited"
          label="Senast uppdaterad"
          showTime={true}
          locales={"sv-SE"}
        />
        <TextField source="orderStatus" label="Status"></TextField>
      </Datagrid>
    </List>
  );
};
