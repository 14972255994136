import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  ReferenceInput,
  SelectInput,
  ImageInput,
  ImageField,
  FormDataConsumer
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

const WithProps = ({ children, ...props }) => children(props);

export const VariantCreate = (props) => (
  <Create {...props}>
    <WithProps>
      {({ record, ...props }) => (
        <SimpleForm
          record={record}
          {...props}
          redirect={`/article/${record.articleId}/show`}
          warnWhenUnsavedChanges
        >
          <ReferenceInput source="articleId" reference="article" disabled>
            <SelectInput optionText="name" />
          </ReferenceInput>

          <TextInput
            source="name"
            validate={required()}
            label="Namn på variant"
          />
          <ImageInput source="variantImageContainer" label="Variantbild" accept="image/*" placeholder={<p>Dra och släpp bilden här</p>}>
            <ImageField source="src" title="title" />
          </ImageInput>

          <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.variantImageContainer) {
                    return (
                        <div>
                            <ImageField source="variantImage" {...rest}/>
                        </div>
                  );
                } 
       
            }}
        </FormDataConsumer>
          <NumberInput
            source="storageAmount"
            validate={required()}
            label="Antal i lager"
          />
          <RichTextInput
            source="variantDescription"
            label="Beskrivning"
            variant="outlined"
          />
        </SimpleForm>
      )}
    </WithProps>
  </Create>
);
