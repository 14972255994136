import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';

export default function AlertDialog(articles) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    console.log(articles.articles)
    setOpen(true);
   
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Visa orderns artiklar
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Artiklar (${articles.articles.length})`}</DialogTitle>
        <DialogContent>
        <List>
    
   {articles.articles.map((value, index) => 
         <ListItem>
         <ListItemAvatar>
           <Avatar alt="img" src={value.image}>
           
           </Avatar>
         </ListItemAvatar>
 
        
         <ListItemText primary={`#${index+1} ${value.name} - ${value.selectedVariant}`} secondary={value.comment} />
       </ListItem>
   )}
    </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Stäng
          </Button>
        
        </DialogActions>
      </Dialog>
    </div>
  );
}