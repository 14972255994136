import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput
} from "react-admin";

const WithProps = ({ children, ...props }) => children(props);

export const CategoryCreate = (props) => (
  <Create {...props}>
    <WithProps>
      {({ record, ...props }) => (
        <SimpleForm
          record={record}
          {...props}
          redirect={`/webshop/${record.webshopId}/show/categories`}
        >
          <ReferenceInput source="webshopId" reference="webshop" disabled>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="name" validate={required()} />
        </SimpleForm>
      )}
    </WithProps>
  </Create>
);
