import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  Button,
  useNotify,
  useRefresh,
  ImageField,
  ImageInput,
  FormDataConsumer
} from "react-admin";

import RichTextInput from "ra-input-rich-text";

export const ArticleEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="show">
        <TextInput source="name" label="Namn" validate={required()} />
        <TextInput
          source="articleNumber"
          label="Artikelnummer"
          validate={required()}
        />
        <TextInput
          source="supplierName"
          label="Märke/Leverantör"
          validate={required()}
        />
           <ImageInput source="articleImageContainer" label="Artikelbild" accept="image/*" placeholder={<p>Dra och släpp bilden här</p>} >
          <ImageField source="src" title="title" />
        </ImageInput>

        <FormDataConsumer>
              {({formData, dispatch, ...rest}) => {
                  if (!formData.articleImageContainer) {
                      return (
                          <div>
                              <ImageField source="articleImage" {...rest}/>
                          </div>
                    );
                  } 
        
              }}
          </FormDataConsumer>
        <NumberInput
          source="buyPrice"
          label="Inköpspris (inkl Moms)"
          helperText="Detta pris syns inte för kund"
        />
        <NumberInput
          source="standardSellPrice"
          label="Rekommenderat pris (inkl Moms)"
          validate={required()}
        />
        <RichTextInput
          source="description"
          label="Beskrivning"
          validate={required()}
          variant="outlined"
        />
      </SimpleForm>
    </Edit>
  );
};
