// in src/MyLoginPage.js
import * as React from "react";
import { useState } from "react";
import { useLogin, useNotify, Notification, usePermissions } from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import { Card, CardContent, TextField, Button } from "@material-ui/core";
import { Api } from "../Custom/Services/apiCalls";

import { useHistory } from "react-router";
const Settings = ({ theme }) => {
  const { permissions } = usePermissions();
  const [email, setEmail] = useState("");
  const history = useHistory();
  const login = useLogin();
  const notify = useNotify();
  const submit = (e) => {
    e.preventDefault();
    Api.simulateUser(email);
    notify("Inloggad som " + email);
    history.push("/article");
  };

  return (
    <ThemeProvider theme={theme}>
      <Card>
        <CardContent>
          {permissions === "Admin" && (
            <div>
              <h2>Admin</h2>
              <br></br>
              <form onSubmit={submit}>
                <TextField
                  label="Användare (Email)"
                  name="uuid"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br></br>
                <Button variant="contained" color="primary" type="submit">
                  Simulera
                </Button>
              </form>

              <Notification />
            </div>
          )}
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};

export default Settings;
