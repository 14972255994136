import * as React from "react";
import { Avatar } from "@material-ui/core";

function Render(record) {
  if(record.variantImage) {
    return !record.variantImageThumb ? record.variantImage : record.variantImageThumb
  }
  
  if(record.articleImage) {
    return !record.articleImageThumb ? record.articleImage : record.articleImageThumb
  }

}

export const SmallImage = ({ record = {} }) => (
  <a href={Render(record)}>
    <Avatar src={Render(record)} alt="Img"></Avatar>{" "}
  </a>
);
SmallImage.defaultProps = { label: "Img" };
