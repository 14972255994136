import { fetchUtils } from "ra-core";
import jsonServerProvider from "ra-data-json-server";
import { Urls } from "../Urls";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const convertFileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

const dataProvider = jsonServerProvider(Urls.api, httpClient);

const extendedProvider = {
  ...dataProvider,
  create: async (resource, params) => {
    if(resource === 'article') {
      if(params.data.articleImageContainer) {
        const pic = params.data.articleImageContainer.rawFile;
        if (pic instanceof File) {
          let base64String = await convertFileToBase64(pic)
          params.data.articleImage = base64String;        
        }
      }
    }

    if(resource === 'variant') {
      if(params.data.variantImageContainer) {
        const pic = params.data.variantImageContainer.rawFile;
        if (pic instanceof File) {
          let base64String = await convertFileToBase64(pic)
          params.data.variantImage = base64String;        
        }
      }
    }
    return dataProvider.create(resource, params)
   },
   update: async (resource, params) => {
    if(resource === 'article') {
      if(params.data.articleImageContainer) {
        const pic = params.data.articleImageContainer.rawFile;
        if (pic instanceof File) {
          let base64String = await convertFileToBase64(pic)
          params.data.articleImage = base64String;        
        }
      }
    }

    if(resource === 'variant') {
      if(params.data.variantImageContainer) {
        const pic = params.data.variantImageContainer.rawFile;
        if (pic instanceof File) {
          let base64String = await convertFileToBase64(pic)
          params.data.variantImage = base64String;        
        }
      }
    }
    return dataProvider.update(resource, params)
  }
}


export default extendedProvider;