import * as React from "react";
import { Route } from "react-router-dom";
import Settings from "./Settings";
import Register from "./Register";
import { Authenticated } from "react-admin";
import profile from "../UserProfile/Show/UserProfileShow";

export default [
  <Route
    exact
    path="/settings"
    render={() => (
      <Authenticated>
        <Settings></Settings>
      </Authenticated>
    )}
  />,
  <Route path="/register" component={Register} noLayout></Route>
];
