import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  Filter,
  ReferenceField,
  AutocompleteInput,
  TextField,
  FunctionField
} from "react-admin";
import { Fragment, useState } from "react";
import { useForm } from "react-final-form";
import { FormDataConsumer } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useQueryWithStore, Loading, Error } from "react-admin";
import { useDataProvider, useNotify, useRedirect, Button } from "react-admin";
import { Api } from "../Custom/Services/apiCalls";
import { StringHelper } from "../Custom/Services/StringHelper";

const WithProps = ({ children, ...props }) => children(props);
const useStyles = makeStyles((theme) => ({
  hidden: {
    visibility: "hidden",
    height: 1
  }
}));

const OrderOrigin = ({ formData, ...rest }) => {
  const form = useForm();
  const classes = useStyles();
  return (
    <Fragment>
      <ReferenceInput
        source="articleId"
        reference="article"
        label="Artikel"
        onChange={(value) => {              
          Api.getPrice(value).then((res) => {    
             form.change("name", res.name);
             form.change(
               "slug",
               StringHelper.slugify(res.name)
             );
            form.change("webshopPrice", res.standardSellPrice)
          }                  
          );
        }}
        {...rest}
      >
        <AutocompleteInput validate={required()} optionText={(record) =>
                  `${record.name} - ${record.standardSellPrice}:-`
                }>

        </AutocompleteInput>
      </ReferenceInput>
      <br></br>
      <TextInput
        source="slug"
        validate={required()}
        label="Slug"
        disabled
        variant="outlined"
        fullWidth
        className={classes.hidden}
        helperText="Detta syns i URL"
      ></TextInput>  
      <TextInput
        fullWidth
        source="name"
        validate={required()}
        label="Namn"
        onChange={(value) => {
          form.change("slug", StringHelper.slugify(value.target.value));
        }}
      />

      <br></br>
      <NumberInput
        source="webshopPrice"
        label="Pris (inkl Moms)"
        validate={required()}
      ></NumberInput>
      <br></br>
      <BooleanInput label="Kan kommenteras" source="isCommentable"  helperText="Tillåter kunden att kommentera artikeln, kan användas till t.ex vad för tryck som önskas" />
      <TextInput
      source="commentName"
      label="Namn på kommentarsrutan"
      helperText="OBS! Detta fält endast relevant om artikeln kan kommenteras. Namnet som visas ovanpå kommentarsrutan. T.ex 'Initialer', 'Önskad Tryck'. Standardvärde: 'Kommentar'" />
        
    </Fragment>
  );
};

export const WebshoparticleCreate = (props) => (
  <Create {...props} title="Lägg till artikel">
    <WithProps>
      {({ record, ...props }) => (
        <SimpleForm
          record={record}
          {...props}
          redirect={`/webshop/${record.webshopId}/show/webshoparticle`}
        >
          <ReferenceInput source="webshopId" reference="webshop" disabled>
            <SelectInput optionText="name" validate={required()} />
          </ReferenceInput>
    
          <FormDataConsumer>
            {(formDataProps) => <OrderOrigin {...formDataProps}></OrderOrigin>}
          </FormDataConsumer>

          <ReferenceInput
            source="categoryId"
            reference="category"
            filterToQuery={function (string) {
              return { webshopId: record.webshopId };
            }}
            label="Kategori"
          >
            <SelectInput optionText="name" validate={required()} />
          </ReferenceInput>
        </SimpleForm>
      )}
    </WithProps>
  </Create>
);
