import * as React from "react";
import { AppBar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import InvertedCover from "../Images/InvertedCover.png";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  spacer: {
    flex: 1
  }
});

const imgStyles = makeStyles({
  img: {
    maxWidth: 135,
    minWidth: 10
  }
});

const Logo = (props) => {
  const classes = imgStyles();

  return (
    <div>
      <img src={InvertedCover} className={classes.img}></img>
    </div>
  );
};

const TopBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props} color="primary">
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Logo></Logo>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default TopBar;
