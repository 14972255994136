import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  ReferenceInput,
  SelectInput,
  DateField,
  ImageField,
  ImageInput,
  FormDataConsumer,
  DeleteButton,
  Toolbar,
  SaveButton,
  useNotify,
  useRefresh,
  useRedirect
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
const WithProps = ({ children, ...props }) => children(props);

//Custom toolbar
const VariantEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton></SaveButton>
    <WithProps>
      {({ record, ...props }) => (
        <DeleteButton
          record={record}
          {...props}
          redirect={`/article/${record.articleId}/show`}
        ></DeleteButton>
      )}
    </WithProps>
  </Toolbar>
);

export const VariantEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify(`sparades`);

    redirect(`/article/${data.articleId}/show`);
    refresh();
  };

  return (
    <Edit {...props} >
      <WithProps>
        {({ record, ...props }) => (
          <SimpleForm
            record={record}
            {...props}            
            warnWhenUnsavedChanges
            redirect={`/article/${record.articleId}/show`}
            toolbar={<VariantEditToolbar red={record} />}
          >
            <ReferenceInput source="articleId" reference="article" disabled>
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput
              source="name"
              validate={required()}
              label="Namn på variant"
            />

<         ImageInput source="variantImageContainer" label="Artikelbild" accept="image/*" placeholder={<p>Dra och släpp bilden här</p>} >
            <ImageField source="src" title="title" />
         </ImageInput>

        <FormDataConsumer>
              {({formData, dispatch, ...rest}) => {
                  if (!formData.variantImageContainer) {
                      return (
                          <div>
                              <ImageField source="variantImage" {...rest}/>
                          </div>
                    );
                  } 
        
              }}
          </FormDataConsumer>
            <NumberInput
              source="storageAmount"
              validate={required()}
              label="Antal i lager"
            />
            <RichTextInput
              source="variantDescription"
              label="Beskrivning"
              variant="outlined"
            />
          </SimpleForm>
        )}
      </WithProps>
    </Edit>
  );
};
