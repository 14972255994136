import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  ReferenceInput,
  SelectInput,
  DeleteButton,
  Toolbar,
  AutocompleteInput,
  SaveButton,
  FormDataConsumer,
  BooleanInput
} from "react-admin";
import { useForm } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import { StringHelper } from "../Custom/Services/StringHelper";
import { Fragment } from "react";

const WithProps = ({ children, ...props }) => children(props);

const useStyles = makeStyles((theme) => ({
  hidden: {
    visibility: "hidden",
    height: 1
  }
}));

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton></SaveButton>
    <WithProps>
      {({ record, ...props }) => (
        <DeleteButton
          record={record}
          {...props}
          redirect={`/webshop/${record.webshopId}/show/webshoparticle`}
        ></DeleteButton>
      )}
    </WithProps>
  </Toolbar>
);

const NameSlug = (props) => {
  const form = useForm();
  const classes = useStyles();

  return (
    <Fragment>
      <TextInput
        source="slug"
        validate={required()}
        label="Slug"
        disabled
        variant="outlined"
        fullWidth
        className={classes.hidden}
        helperText="Detta syns i URL"
      ></TextInput>
      <TextInput
        fullWidth
        source="name"
        validate={required()}
        label="Namn"
        onChange={(value) => {
          form.change("slug", StringHelper.slugify(value.target.value));
        }}
      />
      <BooleanInput label="Kan kommenteras" source="isCommentable"  helperText="Tillåter kunden att kommentera artikeln, kan användas till t.ex vad för tryck som önskas" />
      <TextInput
      source="commentName"
      label="Namn på kommentarsrutan"
      helperText="OBS! Detta fält endast relevant om artikeln kan kommenteras. Namnet som visas ovanpå kommentarsrutan. T.ex 'Initialer', 'Önskad Tryck'. Standardvärde: 'Kommentar'" />
    </Fragment>
  );
};

export const WebshoparticleEdit = (props) => {
  return (
    <Edit {...props}>
      <WithProps>
        {({ record, ...props }) => (
          <SimpleForm
            {...props}
            record={record}
            redirect={`/webshop/${record.webshopId}/show/webshoparticle`}
            toolbar={<EditToolbar></EditToolbar>}
          >
            <ReferenceInput
              source="articleId"
              reference="article"
              label="Artikel"
            >
              {/* <SelectInput
                optionText={(record) =>
                  `${record.name} - ${record.standardSellPrice}:-`
                }
                optionValue="id"
                validate={required()}
              /> */}

              <AutocompleteInput validate={required()} disabled>

              </AutocompleteInput>
            </ReferenceInput>

            <FormDataConsumer>
              {(formDataProps) => <NameSlug {...formDataProps}></NameSlug>}
            </FormDataConsumer>

            <NumberInput
              source="webshopPrice"
              validate={required()}
              label="Säljpris (inkl Moms)"
            />

            <ReferenceInput
              source="categoryId"
              reference="category"
              label="Kategori"
              filterToQuery={function (string) {
                return { webshopId: record.webshopId };
              }}
            >
              <SelectInput optionText="name" validate={required()} />
            </ReferenceInput>
          </SimpleForm>
        )}
      </WithProps>
    </Edit>
  );
};
