import { Urls } from "../Urls";
import decodeJwt from "jwt-decode";

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(Urls.api + "/login", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" })
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token, admin }) => {
        //Succesful login
        const decodedToken = decodeJwt(token);
        localStorage.setItem("token", token);
      });
  },
  logout: () => {
    localStorage.removeItem("token");

    return Promise.resolve();
  },
  checkAuth: (params) => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject({ redirectTo: "/login" });
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const role = decodeJwt(localStorage.getItem("token")).role;
    return role ? Promise.resolve(role) : Promise.reject();
  }
};

export default authProvider;
