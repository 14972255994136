import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import "moment/locale/sv";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  }
}));

const GreenTextTypography = withStyles({
  root: {
    color: "#4caf50"
  }
})(Typography);

function getStepContent(step) {
  return step.comment;
}

export default function OrderStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(
    props.record.orderHistory.length - 1
  );
  const [steps, setSteps] = React.useState(props.record.orderHistory);
  const [stepContent, setStepContent] = React.useState(steps[activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleActiveStep = (index) => {
    setActiveStep(index);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleAddStep = () => {
    const today = new Date();

    const obj = {
      comment: "Steg " + (steps.length + 1),
      createdDate: today.toJSON()
    };
    setSteps((steps) => [...steps, obj]);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    !props.loading && (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label.createdDate}>
              <StepLabel>
                {label.comment}
                <br></br>
                {moment(label.createdDate).format("llll:ss")}
              </StepLabel>
              <StepContent>
                <div className={classes.actionsContainer}>
                  <div>{label.isDone ? "" : <p></p>}</div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steg är klara - Ordern är nu genomförd!</Typography>
          </Paper>
        )}
      </div>
    )
  );
}
