import * as React from "react";
import {
  Button,
  Card,
  Grid,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  CardHeader,
  List,
  Link,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  AppBar,
  Toolbar,
  Divider,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import OrderStepper from "./OrderStepper";

import {
  useMutation,
  Button as Btn,
  useRefresh,
  useRedirect
} from "react-admin";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 350
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const UpdateButton = (props) => {
    const refresh = useRefresh();
    const redirect = useRedirect();
  

    let newObj = JSON.parse(JSON.stringify(props.record))
    newObj.webshopSettings.deliveryOptions[props.index].active = props.active
    newObj.webshopSettings.deliveryOptions[props.index].name = props.desc
    const load = {
      type: "update",
      resource: "webshop",
      payload: {
        id: props.record.id,
        data: newObj
      }
    };
    let deliveryOption = props.record.webshopSettings.deliveryOptions[props.index]




    const [approve, { loading }] = useMutation(load, {
      onSuccess: () => {
        refresh();
      }
     
    });

  
    return <Btn label="Uppdatera" onClick={approve} disabled={loading || props.isDisabled} />;
  };

//Transition animation
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeliveryOptionsUpdater = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [activeSelected, setActiveSelected] = React.useState(JSON.parse(JSON.stringify(props.record.webshopSettings.deliveryOptions[props.index].active)));
  const [activeDesc, setActiveDesc] = React.useState(JSON.parse(JSON.stringify(props.record.webshopSettings.deliveryOptions[props.index].name)));
  const [record, setRecord] = React.useState(props.record);

  const handleClickOpen = (record) => {
    setOpen(true); 
  };

  const handleUpdate = () => {
    setOpen(false);
  };

  const handleActiveChange = (event) => {
    setActiveSelected(event.target.value)
  };

  const handleNameChange = (event) => {
    setActiveDesc(event.target.value)
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={() => handleClickOpen(record)}>
        Ändra
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        closeDialog={handleClose}
      >
        <DialogTitle id="form-dialog-title">Uppdatera Leveransinställning</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item lg={6} xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Aktiv
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={activeSelected}
                  onChange={val => handleActiveChange(val, record)}
                  defaultValue = {activeSelected}
                >
                  <MenuItem value={true}>
                    Ja
                  </MenuItem>
                  <MenuItem value={false}>
                    Nej
                  </MenuItem>                
                </Select>
                <br />             
                <TextField required id="standard-required" label="Namn (Visas i webshoppen)" onChange={handleNameChange} defaultValue={activeDesc} />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <UpdateButton
            record={record}
            active={activeSelected}
            desc={activeDesc}
            index={props.index}
            closeDialog={handleUpdate}
            isDisabled={activeSelected === null || (activeDesc === null || activeDesc === "")}
          ></UpdateButton>
          <Button autoFocus onClick={handleClose} variant="outlined">
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeliveryOptionsUpdater;
