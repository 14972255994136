import * as React from "react";
import {
  Button,
  Card,
  Grid,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  CardHeader,
} from "@material-ui/core";
import { useShowController, useQuery, Loading, Error } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";
import OrderStepper from "./OrderStepper";
import OrderStatusUpdater from "./OrderStatusUpdater";
import OrderInfoShow from "./OrderInfoShow"

const paymentFormatter = (code) => {
  if (code === "PAYMENT_DECLINED") return "Betalning misslyckades";
  else if (code === "PAYMENT_SUCCESFUL") return "Betald";
  else if (code === "Pending") return "Inväntar betalning";
};

const useStyles = makeStyles((theme) => ({
  root: {},
  spacing: {
    margin: theme.spacing(3)
  },
  listItem: {
    marginBottom: theme.spacing(2)
  },
  price: {
    color: "green"
  },
  variantList: {
    position: "relative",
    overflow: "auto",
    maxHeight: 350
  },
  card: {
    maxHeight: 410,
    overflow: "auto"
  },
  gridHeight: {
    maxHeight: 455,
    minHeight: 300
  }
}));
const CustomShow = (props) => {
  const {
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    loaded, // boolean that is false until the record is available
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version // integer used by the refresh feature
  } = useShowController(props);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {!loading && (
        <div>
          <Grid container spacing={3}>
            <Grid item>
              <h1>Order</h1>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Grid item>
                <Card>
               
                  <CardHeader title="Orderinformation"></CardHeader>
                  <CardContent>
                  <Typography variant="body1">Pris (totalt)</Typography>
                    <h4>{record.totalSum}</h4>
                    <br />
                    <Typography variant="body1">Artiklar</Typography>
                    <OrderInfoShow articles={record.orderArticles}></OrderInfoShow>
                    <br></br>
                    <Typography variant="body1">Ordernummer: </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {record.orderNumber}
                    </Typography>
                    <br></br>
                    <Typography variant="body1">Beställd: </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {record.createdDate}
                    </Typography>
                    <br></br>
                    <Typography variant="body1">Kund:</Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {record.email}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {record.phone}
                    </Typography>
                    <br></br>
                    <Typography variant="body1">
                      Skicka orderinfo via epost:
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {record.sendEmailsToClient ? 'Ja' : 'Nej'}
                    </Typography>
                    <Typography variant="body1">Betalningssätt</Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {record.paymentMethod === "INVOICE" ? "Faktura" : "Klarna"}
                    </Typography>
                    <br />
                    <Typography variant="body1">Leveransalternativ</Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      <h4>{record.deliverMethod === 'PICKUP_SERVICE_POINT' ? "DHL SERVICE POINT" : record.deliverMethod}</h4>
                      {record.shippingInfo && 
                        (<div>
                            <p>
                            Namn: {record.shippingInfo.firstName} {record.shippingInfo.lastName}
                            </p>
                            <p>
                            Postnummer :{record.shippingInfo.postalCode}
                            </p>
                            <p>
                            Adress: {record.shippingInfo.streetAddress}
                            </p>
                            <p>
                            Stad: {record.shippingInfo.city}
                            </p>

                            <br />

                            {record.deliverMethod === 'PICKUP_SERVICE_POINT' && <div>
                              <b>Service Point:</b>
                              <p>
                                {record.shippingInfo.selectedPickupLocation.pickupLocation.address} <br />
                                {record.shippingInfo.selectedPickupLocation.pickupLocation.city}  <br />
                                {record.shippingInfo.selectedPickupLocation.pickupLocation.locationName}  
                              </p>  
                              
                            </div>}
                        </div>
                          
                        )
                        
                      
                      }
                    </Typography>
                 
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid item>
                <Card>
                  <CardHeader title="Tidslinje"></CardHeader>
                  <CardContent>
                    <OrderStepper
                      record={record}
                      loading={loading}
                    ></OrderStepper>
                    <OrderStatusUpdater record={record}></OrderStatusUpdater>
             
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

const OrderShow = (props) => <CustomShow {...props}></CustomShow>;

export default OrderShow;
