// in src/MyLoginPage.js
import * as React from "react";
import { useState } from "react";
import { useLogin, useNotify, Notification } from "react-admin";

import {
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  CardHeader,
  Container,
  Checkbox,
  FormControlLabel,
  FormControl,
  CardActions,
  Snackbar
} from "@material-ui/core";
import { Api } from "../Custom/Services/apiCalls";

import { useHistory } from "react-router";

//img
import Image from "../Img/blue-gradient.jpg";

const styles = {
  bgContainer: {
    backgroundImage: `url(${Image})`,
    height: 1356
  }
};

const Register = () => {
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(true);
  const errorText = "";

  const history = useHistory();

  const submit = async (e) => {
    e.preventDefault();
    var res = await Api.register({ userName: email, password: password });
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    if (res.status != 200) {
      alert(res.title);
    } else {
      history.push("/login");
      alert("Användare skapad, du kan nu logga in");
    }
  };

  const onChange = (event) => {
    const emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  };

  return (
    <Container style={styles.bgContainer} maxWidth>
      <div style={{ padding: 45 }}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={6}>
            <form onSubmit={submit}>
              <Card>
                <CardHeader title="Skapa konto"></CardHeader>
                <CardContent>
                  <TextField
                    label="Epost"
                    fullWidth
                    error={errorText.length === 0 ? false : true}
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  ></TextField>
                </CardContent>
                <CardContent>
                  <TextField
                    type="password"
                    label="Lösenord"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></TextField>
                </CardContent>
                <CardContent>
                  {" "}
                  <TextField
                    label="Bekräfta Lösenord"
                    type="password"
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  ></TextField>
                </CardContent>
                <CardContent>
                  <FormControlLabel
                    label="Jag accepterar villkoren"
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                        color="primary"
                        inputProps={{ "aria-label": "primary" }}
                        name="terms"
                      />
                    }
                  ></FormControlLabel>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={
                      password.length <= 0 ||
                      email.length <= 0 ||
                      confirmPassword.length <= 0 ||
                      !checked
                    }
                  >
                    Skicka
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default Register;
