import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,

  SelectInput,
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
  BooleanInput,
  FormDataConsumer
} from "react-admin";
import { useForm } from "react-final-form";
import { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StringHelper } from "../Custom/Services/StringHelper";
import RichTextInput from "ra-input-rich-text";
const WebshopToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton></SaveButton>
    <DeleteWithConfirmButton redirect="list"></DeleteWithConfirmButton>
  </Toolbar>
);

const useStyles = makeStyles((theme) => ({
  hidden: {
    visibility: "hidden",
    height: 1
  }
}));
const NameSlug = (props) => {
  const form = useForm();
  const classes = useStyles();
  return (
    <Fragment>
      <TextInput
        source="name"
        label="Namn på webbshop"
        validate={required()}
        variant="outlined"
        onChange={(value) => {
          form.change("slug", StringHelper.slugify(value.target.value));
        }}
      />
      <br></br>
      <TextInput
        source="slug"
        validate={required()}
        label="Slug"
        disabled
        className={classes.hidden}
        variant="outlined"
        helperText="Detta är sökvägen i webshoppens URL. Måste vara unik"
      ></TextInput>
    </Fragment>
  );
};

export const WebshopEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show" toolbar={<WebshopToolbar></WebshopToolbar>}>
      <FormDataConsumer>
        {(formDataProps) => <NameSlug {...formDataProps}></NameSlug>}
      </FormDataConsumer>
      <TextInput
        multiline
        source="description"
        label="Beskrivning"
        validate={required()}
        fullWidth
      />
      <SelectInput
        validate={required()}
        source="VAT"
        label="Momssats"
        optionValue="id"
        optionText="name"
        choices={[
          { id: 0.8, name: "25%" },
          { id: 0.8929, name: "12%" }
        ]}
      />
      <BooleanInput
        validate={required()}
        source="isShowroom"
        label="Göm priser"
      ></BooleanInput>
      <BooleanInput
        validate={required()}
        source="showRecommendedPrices"
        label="Visa rekommenderade priser"
      ></BooleanInput>
      <BooleanInput
        validate={required()}
        source="withVAT"
        label="Visa priser inkl moms"
      ></BooleanInput>
      <BooleanInput
        validate={required()}
        source="isOnline"
        label="Online"
      ></BooleanInput>
       <RichTextInput multiline source="terms" label="Leverans/Köpvillkor" validate ={required()} fullWidth />
    </SimpleForm>
  </Edit>
);
