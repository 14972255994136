import * as React from "react";
import {
  Show,
  TabbedShowLayout,
  TextField,
  DateField,
  FunctionField,
  RichTextField,
  EditButton,
  ReferenceField,
  ArrayField,
  Datagrid,
  Tab,
  ReferenceManyField,
} from "react-admin";
import EditIcon from "@material-ui/icons/Edit";
import {
  Card,
  Grid,
  CardContent,
  CardActions,
  Typography,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  IconButton
} from "@material-ui/core";

import {
  useMutation,
  Button as Btn,
  useRefresh,
  useRedirect
} from "react-admin";

import Add from "@material-ui/icons/Add";
import { Button, Avatar } from "@material-ui/core";

import { Link } from "react-router-dom";

import DeliveryOptionsUpdater from "./Custom/DeliveryOptionsUpdater"

//Actions

const CreateCategory = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/category/create",
      state: { record: { webshopId: record.id } }
    }}
    color="primary"
    startIcon={<Add />}
  >
    Ny Kategori
  </Button>
);

const CreateWebshopArticle = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/webshoparticle/create",
      state: { record: { webshopId: record.id } }
    }}
    color="primary"
    startIcon={<Add />}
  >
    Lägg till artikel
  </Button>
);



const Title = ({ record }) => {
  return <span>{record ? `${record.name}` : ""}</span>;
};

const Expand = (props) => (
  <ArrayField {...props} source="webshopArticles" label="Artiklar">
    <Datagrid>
      <TextField sortable={false} source="name" label="Artikelnamn"></TextField>
      <TextField
        sortable={false}
        source="webshopPrice"
        label="Pris"
      ></TextField>
    </Datagrid>
  </ArrayField>
);

const LinkToWebshop = (props) => {
  if (props.record.isOnline) {
    return (
      <div>
        <p>Visa Webshop:</p>
        <a
          href={`https://shop.retailhut.se/${props.record.slug}`}
          target="_blank"
        >
          {`https://shop.retailhut.se/${props.record.slug}`}
        </a>
        <br></br>
        <br></br>
      </div>
    );
  } else {
    return <p></p>;
  }
};


const DeliveryOptions = ({record}) => {
 if (record.webshopSettings == null) return <div></div>
 return ( <div>
    <Card>
    <CardHeader title="Leveransalternativ: "></CardHeader>
    <List>
      {record.webshopSettings.deliveryOptions.map((item, index) => (
        <ListItem key={item.name}>
          <ListItemText
            primary={item.name}
          ></ListItemText>
          <ListItemText
            primary={"Aktiv"}
            secondary={item.active ? "Ja" : "Nej"}
          ></ListItemText>
            <ListItemSecondaryAction>
              <Grid spacing={2}>
                <DeliveryOptionsUpdater record={record} index={index}></DeliveryOptionsUpdater>
              </Grid>
            </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
    </Card>
    <br></br>
</div>)
}

export const WebshopShow = (props) => (
  <Show {...props} title={<Title></Title>}>
    <TabbedShowLayout>
      <Tab label="Info">
        <LinkToWebshop {...props}></LinkToWebshop>
        <TextField source="name" label="Namn" />
        <FunctionField
          label="Status"
          render={(record) => {
            if (record.isOnline) {
              return "Online";
            } else {
              return "Offline";
            }
          }}
        />
        <FunctionField
          label="Göm priser"
          render={(record) => {
            if (record.isShowroom) {
              return "Ja";
            } else {
              return "Nej";
            }
          }}
        />
        <FunctionField
          label="Visa priser inkl moms"
          render={(record) => {
            if (record.withVAT) {
              return "Ja";
            } else {
              return "Nej";
            }
          }}
        />
        <FunctionField
          label="Visa rekommenderade priser"
          render={(record) => {
            if (record.showRecommendedPrices) {
              return "Ja";
            } else {
              return "Nej";
            }
          }}
        />

        <DateField source="createdDate" label="Skapad" />
        <RichTextField source="description" label="Beskrivning" />
        <FunctionField
          label="Momssats"
          render={(record) => {
            if (record.vat < 0.8929) {
              return "25%";
            } else {
              return "12%";
            }
          }}
        />
        <hr />
        <br></br>         
        <EditButton></EditButton>
        <br></br>

      

      </Tab>
      <Tab label="Design" path="webshopdesign">
        <ReferenceManyField
          reference="webshopdesign"
          target="id"
          addLabel={false}
        >
          <Datagrid sortable="false">
            <FunctionField
              label="Header"
              render={function (record) {
                if (record.headerImage === null) {
                  return "Ingen bild";
                } else {
                  return <Avatar alt="asd" src={record.headerImage}></Avatar>;
                }
              }}
            />

            <FunctionField
              label="Logo"
              render={function (record) {
                if (record.webshopLogoImage === null) {
                  return "Ingen bild";
                } else {
                  return (
                    <Avatar alt="asd" src={record.webshopLogoImage}></Avatar>
                  );
                }
              }}
            />
            <EditButton></EditButton>
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Artiklar" path="webshoparticle">
        <br></br>
        <CreateWebshopArticle></CreateWebshopArticle>
        <ReferenceManyField
          reference="webshoparticle"
          target="webshopId"
          addLabel={false}
        >
          <Datagrid>
            <TextField source="name" label="Artikelnamn" />
            <TextField source="categoryName" label="Kategori"></TextField>
            <TextField source="webshopPrice" label="Webbshop-pris"></TextField>
            <ReferenceField
              label="Rek pris"
              source="articleId"
              reference="article"
              link={false}
            >
              <TextField source="standardSellPrice" />
            </ReferenceField>
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Kategorier" path="categories">
        <br></br>
        <CreateCategory></CreateCategory>
        <ReferenceManyField
          reference="category"
          target="webshopId"
          addLabel={false}
        >
          <Datagrid expand={<Expand />}>
            <TextField source="name" label="Namn" />
            <FunctionField
              label="Antal Produkter i kategori"
              render={function (record) {
                return record.webshopArticles.length;
              }}
            />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Leveransalternativ">
          <DeliveryOptions></DeliveryOptions>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
