import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  Filter,
  SearchInput,
  UrlField,
  ShowButton,
  CreateButton
} from "react-admin";
import { Box, Button, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import FileCopy from "@material-ui/icons/FileCopy";

import Typography from "@material-ui/core/Typography";
import { SmallImage } from "../Custom/SmallImage";
//Empty page
const Empty = ({ basePath, resource }) => (
  <Box textAlign="center" m={4}>
    <Typography variant="h4" paragraph>
      Inga artiklar finns sparade
    </Typography>

    <CreateButton basePath={basePath} label="Skapa artikel" />
  </Box>
);

const CloneArticle = ({ record, basePath }) => {
  return (
    <IconButton
      component={Link}
      variant="outlined"
      color="primary"
      to={{
        pathname: "/article/create",
        state: {
          record: {
            name: record.name,
            description: record.description,
            supplierName: record.supplierName,
            buyPrice: record.buyPrice,
            standardSellPrice: record.standardSellPrice,
            articleImage: record.articleImage,
            articleNumber: record.articleNumber
          }
        }
      }}
    >
      <FileCopy></FileCopy>
    </IconButton>
  );
};

//Filter
const NameFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const ArticleList = (props) => (
  <List
    filters={<NameFilter></NameFilter>}
    {...props}
    title="Artiklar"
    empty={<Empty /> }
    sort={{ field: 'createdDate', order: 'DESC' }}
  >
    <Datagrid>
      <SmallImage></SmallImage>

      <TextField source="name" label="Namn" />

      <TextField source="articleNumber" label="Artikelnummer" />
      <TextField source="supplierName" label="Märke/Leverantör" />

      <NumberField source="standardSellPrice" label="Rek. Pris" />
      <DateField source="createdDate" label="Skapad" />
      <ShowButton></ShowButton>
      <CloneArticle {...props}></CloneArticle>
    </Datagrid>
  </List>
);
